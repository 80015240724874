<template>
  <div class="wrapper">
    <AppHeader />
    <br />
    <Suspense>
      <template #default>
        <HomePage />
      </template>
      <template #fallback>
        <Loading />
      </template>
    </Suspense>
    <AppFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import AppHeader from "@/components/layouts/AppHeader.vue";
import AppFooter from "@/components/layouts/AppFooter.vue";
import { defineAsyncComponent, Suspense } from "vue";
// import HomePage from "@/components/Pages/HomePage.vue";
import Loading from "@/components/Spinners/Loading.vue";
const HomePage = defineAsyncComponent(() =>
  import('@/components/Pages/HomePage.vue')
)
export default {
  name: "Home",
  components: {
    AppHeader,
    AppFooter,
    Suspense,
    HomePage,
    Loading,
  },
};
</script>
